import Pristine from 'pristinejs';

const contactForm = document.getElementById("contact-form");
const pristine = new Pristine(contactForm, {
  classTo: 'form-item',
  errorClass: 'is-invalid',
  successClass: 'is-valid',
  errorTextParent: 'form-item',
  errorTextTag: 'span',
  errorTextClass: 'text-error'
}, false);


const displayFormMessage = (messageText, messageType) => {
  const messageClass = (messageType === "success") ? "form-message--success" : "form-message--error";
  const formMessage = contactForm.getElementsByClassName("form-message")[0];

  formMessage.textContent = messageText;
  formMessage.classList.add(messageClass);
  setTimeout(() => {
    formMessage.classList.remove(messageClass);
  }, 10000);
}

const handleSubmit = async (e) => {
  e.preventDefault();
  const contactFormData = new FormData(contactForm);
  const isValid = pristine.validate();

  if (isValid) {
    try {
      await fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams(contactFormData).toString(),
      });
      displayFormMessage("Thanks for the message, I'll be in touch!", "success");
    } catch (error) {
      console.error('error', error);
      displayFormMessage("There was an error sending your message. Please try again.", "error");
    }
  }

}

contactForm.addEventListener('submit', handleSubmit);